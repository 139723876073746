import { postMultiFormDataAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { TokenExpiryWrapper } from './TokenManager'

export interface IUploadRequest extends IBodyRequestModel {
  files: File
}

export interface IBulkUpdateRequest extends IUploadRequest {
  stage: 'rectification' | 'inspection'
}

export interface IUploadResponse {
  success: boolean
  message?: string
}

export const submitAddProjFile = TokenExpiryWrapper(
  async (request: IUploadRequest): Promise<IUploadResponse | null> => {
    try {
      const url = `/fileupload/addproj`
      const formData = new FormData()
      Object.keys(request)
        .filter((key) => key !== 'token')
        .forEach((key) => {
          formData.append(key, request[key] || '')
        })

      return await postMultiFormDataAsync(url, request.token || '', formData)
    } catch (error) {
      console.log('** Error uploading "add project file"...')
      console.log(error)
    }
    return null
  },
  [],
  null,
)

export const submitBulkUpdateFile = TokenExpiryWrapper(
  async (request: IBulkUpdateRequest): Promise<IUploadResponse | null> => {
    try {
      const url = `/fileupload/bulkupdate/${request.stage}`
      const formData = new FormData()
      Object.keys(request)
        .filter((key) => key !== 'token')
        .forEach((key) => {
          formData.append(key, request[key] || '')
        })

      return await postMultiFormDataAsync(url, request.token || '', formData)
    } catch (error) {
      console.log('** Error uploading "bulk update file"...')
      console.log(error)
    }
    return null
  },
  [],
  null,
)
