import { postAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { TokenExpiryWrapper } from './TokenManager'
import { RawPropertyData } from '../models/data/PropertyData'

export interface ISubmitPropEditFormRequest extends IBodyRequestModel {
  propData: Partial<RawPropertyData>
}

export interface ISubmitFormResponse {
  success: boolean
  message?: string
}

export const submitPropEditForm = TokenExpiryWrapper(
  async (req: ISubmitPropEditFormRequest): Promise<ISubmitFormResponse> => {
    const url = `/submitpropedit`
    return await postAsync(url, req)
  },
  [],
  null,
)
