import React, { FC, useState, useEffect, useCallback, ReactNode } from 'react'
import { appInsights } from '../api/AppInsights'
import {
  Container,
  Grid,
  Text,
  SearchBox,
  Dropdown,
  Table,
  TableRow,
  TableCell,
  Button,
  Loader,
  Modal,
  useModal,
  FormInput,
  TabContent,
  TabMenu,
} from '@aurecon-creative-technologies/styleguide'
import Page from '../components/Page'
import {
  propertyDataMap,
  PropertyData,
  isPropertyData,
  RawPropertyData,
  wrapPropertyData,
  mapKeysToRawPropertyData,
} from '../models/data/PropertyData'
import { getDBInfo } from '../api/DbInfoService'
import { submitPropEditForm } from '../api/FormSubmitService'

import Style from '../styles/PropertyPage.module.sass'

interface ITableHeader {
  // column header for data table
  label: string // column label
  key: string // key to retrieve property data
  filter?: ReactNode // component to be used as filter handle
}

const Property: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Property' })
  // -------- state hooks
  const [propData, setPropData] = useState<PropertyData[]>() // full property data list
  const [selectedProp, setSelectedProp] = useState<PropertyData>() // data of selected property
  const [editedProp, setEditProp] = useState<Partial<PropertyData>>() // edited property data
  const [formPayload, setFormPayload] = useState<Partial<RawPropertyData>>() // payload to be submitted
  const [propFilter, setPropFilter] = useState<Partial<PropertyData>>({}) // filter on property data
  // ui elements
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [modalLoading] = useState<boolean>(false)
  const [editForm, setEditForm] = useState<boolean>(false)

  type DropdownOption = {
    insptAccessStatus: string[]
    insptFieldStatus: string[]
    rectStage: string[]
    rectStatus: string[]
  }
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption>()

  const { isShowing, toggleModal } = useModal()

  const fetchPropDataWithFilter = useCallback(async (filter: Partial<PropertyData>) => {
    setTableLoading(true)
    // load property data into memory for display
    const result = await getDBInfo({ filters: JSON.stringify(mapKeysToRawPropertyData(filter)) })
    if (result?.success && !!result.data) {
      // parse data from response
      const rawData = result.data['response']['data']
      const rawDropdownOpt = result.data['response']['filters'] as DropdownOption

      // map raw data
      const data = []
      for (const raw of rawData) {
        const wrappedData = wrapPropertyData(raw, propertyDataMap)
        if (wrappedData && isPropertyData(wrappedData)) {
          data.push(wrappedData)
        }
      }
      setPropData(data)

      // map dropdown options
      let wrappedDropdownOpt = {} as DropdownOption
      for (const mapKey in propertyDataMap) {
        const rawKey: keyof RawPropertyData = propertyDataMap[mapKey].rawKey
        const options = rawDropdownOpt[rawKey]
        wrappedDropdownOpt = { ...wrappedDropdownOpt, [mapKey]: options }
      }
      setDropdownOptions(wrappedDropdownOpt)

      setTableLoading(false)
    }
  }, [])

  // ----------- effect hook
  useEffect(() => {
    fetchPropDataWithFilter(propFilter)
  }, [fetchPropDataWithFilter, propFilter])

  // ----------- user action handlers
  const onCellClick = (propId: number) => () => {
    // action on the selected property
    const property = propData?.find((x) => x.propId === propId)
    if (property) {
      setSelectedProp(property)
      setEditProp({ ...property })
      setEditForm(false)
      setFormPayload({})
      toggleModal()
    }
  }

  const onSearchChange = (key: keyof PropertyData) => (value: string | number) => {
    // search box text change
    let newFilter: Partial<PropertyData> = { ...propFilter }
    if (value) {
      newFilter = { ...propFilter, [key]: value }
    } else {
      // remove property if filter is empty
      delete newFilter[key]
    }
    setPropFilter(newFilter)
  }

  const onSearchClear = (key: keyof PropertyData) => () => {
    // search box text change
    const newFilter: Partial<PropertyData> = { ...propFilter }
    delete newFilter[key]
    setPropFilter(newFilter)
  }

  const onDropdownChange = (key: keyof PropertyData) => (selectedItemId: string | number) => {
    // new filter selected
    let newFilter: Partial<PropertyData> = { ...propFilter }
    if (selectedItemId) {
      newFilter = { ...propFilter, [key]: selectedItemId }
    } else {
      // remove property if filter is empty
      delete newFilter[key]
    }
    setPropFilter(newFilter)
  }

  const toggleFormEdit = () => {
    setEditForm(!editForm)
  }

  const onEditFormChange = (key: keyof PropertyData) => (value: string | number) => {
    const newData = { ...editedProp, [key]: value }
    const rawKey = propertyDataMap[key]?.rawKey
    const dataType = propertyDataMap[key]?.dataType
    if (rawKey) {
      // convert value to proper format
      let payloadValue = undefined
      switch (dataType) {
        case 'date':
          payloadValue = Date.parse(value.toString())
          break
        default:
          payloadValue = value
      }
      // store payload
      let newFormPayload = { ...formPayload, 'Property Number': selectedProp?.propId }
      newFormPayload = { ...newFormPayload, [rawKey]: payloadValue }
      setFormPayload(newFormPayload)
    }
    setEditProp(newData)
  }

  const onCancelFormEdit = () => {
    // discard user input
    setEditProp({ ...selectedProp })
    setEditForm(false)
    setFormPayload({})
  }

  const onSubmitFormEdit = async () => {
    // do nothing if payload is empty
    if (formPayload && Object.keys(formPayload).length === 0) return
    // submit form data
    setEditForm(false)
    if (formPayload) {
      const result = await submitPropEditForm({ propData: formPayload })
      // TODO: add error message for failed submission
      console.log('form submitted')
      console.log(result)
    }
    fetchPropDataWithFilter(propFilter)
    toggleModal()
  }

  // ------------- React Components
  const loadingSpinner = () => {
    return (
      <Grid cssClass={Style.loader}>
        <Loader label='Loading Property Data' />
      </Grid>
    )
  }
  // ---- table setup
  type TableHeader = {
    label: string
    key: keyof PropertyData
    filter?: React.ReactElement
  }
  // table header
  const tableHeaders: TableHeader[] = [
    {
      label: 'Property ID',
      key: 'propId',
      filter: <SearchBox placeholder='Search' onSearch={onSearchChange('propId')} onClear={onSearchClear('propId')} />,
    },
    {
      label: 'Property Address',
      key: 'propAdd',
      filter: (
        <SearchBox placeholder='Search' onSearch={onSearchChange('propAdd')} onClear={onSearchClear('propAdd')} />
      ),
    },
    {
      label: 'Inspection Access Status',
      key: 'insptAccessStatus',
      filter: (
        <Dropdown
          placeholder='-'
          default
          selectedItem={propFilter['insptAccessStatus']}
          items={dropdownOptions ? dropdownOptions['insptAccessStatus'].map((opt) => ({ id: opt, label: opt })) : null}
          clearSelectionItem='- Clear -'
          onSelectItem={onDropdownChange('insptAccessStatus')}
        />
      ),
    },
    {
      label: 'Inspection Field Status',
      key: 'insptFieldStatus',
      filter: (
        <Dropdown
          placeholder='-'
          default
          selectedItem={propFilter['insptFieldStatus']}
          items={dropdownOptions ? dropdownOptions['insptFieldStatus'].map((opt) => ({ id: opt, label: opt })) : null}
          clearSelectionItem='- Clear -'
          onSelectItem={onDropdownChange('insptFieldStatus')}
        />
      ),
    },
    {
      label: 'Rectification Stage',
      key: 'rectStage',
      filter: (
        <Dropdown
          placeholder='-'
          default
          selectedItem={propFilter['rectStage']}
          items={dropdownOptions ? dropdownOptions['rectStage'].map((opt) => ({ id: opt, label: opt })) : null}
          clearSelectionItem='- Clear -'
          onSelectItem={onDropdownChange('rectStage')}
        />
      ),
    },
    {
      label: 'Retification Overall Status',
      key: 'rectStatus',
      filter: (
        <Dropdown
          placeholder='-'
          default
          selectedItem={propFilter['rectStatus']}
          items={dropdownOptions ? dropdownOptions['rectStatus'].map((opt) => ({ id: opt, label: opt })) : null}
          clearSelectionItem='- Clear -'
          onSelectItem={onDropdownChange('rectStatus')}
        />
      ),
    },
    { label: 'Record Modified', key: 'modTimestamp' },
  ]

  // table content
  const tableContent = (headers: ITableHeader[], data?: PropertyData[]) => {
    // return empty row with null or invalid data
    // TODO: add error message
    if (!data) {
      return <TableRow></TableRow>
    }

    // data table content
    return data.map((row: PropertyData) => {
      const cells = headers.map((header: ITableHeader) => {
        if (!isPropertyData(row)) return null

        // table cell content
        const dataKey = header.key
        const dataValue = row[dataKey]
        return (
          <TableCell key={dataKey} onClick={onCellClick(row.propId)}>
            {dataValue}
          </TableCell>
        )
      })

      // populate table row
      return <TableRow key={row.propId}>{cells}</TableRow>
    })
  }

  const propDataTable = () => {
    // data table for property data
    return (
      <Grid xs={12}>
        <Table headers={tableHeaders}>{tableLoading ? null : tableContent(tableHeaders, propData)}</Table>
        {tableLoading ? loadingSpinner() : null}
      </Grid>
    )
  }

  const propDetailFormTabContent = (mapKeys: (keyof PropertyData)[]) => {
    // content of each tab of property detail form
    return (
      <Grid item xs={12}>
        {mapKeys.map((key) => {
          // extract field properties
          const rawKey = propertyDataMap[key]?.rawKey
          const dataType = propertyDataMap[key]?.dataType
          const options = propertyDataMap[key]?.options
          const readOnly = propertyDataMap[key]?.readOnly
          const hidden = propertyDataMap[key]?.hidden
          const group = propertyDataMap[key]?.group
          // if rectification stage is No, then disable edit in Rectification tab
          const hideByRectStage = group === 'rect' && editedProp?.rectStage === 'No'

          if (hidden) return // skip this field if it's hidden

          return (
            <Grid key={key} cell item xs={12}>
              {options && editForm && !hideByRectStage ? (
                <Dropdown
                  label={rawKey}
                  items={options.map((opt) => ({ id: opt, label: opt }))}
                  selectedItem={editedProp ? editedProp[key]?.toString() : undefined}
                  onSelectItem={onEditFormChange(key)}
                />
              ) : (
                <FormInput
                  label={rawKey}
                  placeholder='---'
                  value={editedProp ? editedProp[key]?.toString() : undefined}
                  readonly={!editForm || readOnly || hideByRectStage}
                  default
                  onChange={onEditFormChange(key)}
                  type={dataType === 'date' ? 'date' : 'text'}
                />
              )}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const propDetailForm = () => {
    // property detail form with fields groupped in tab menu
    // extract keys based on grouping
    const mapKeys = Object.keys(propertyDataMap) as (keyof PropertyData)[]
    const propKeys = [] as (keyof PropertyData)[]
    const insptKeys = [] as (keyof PropertyData)[]
    const rectKeys = [] as (keyof PropertyData)[]
    for (const key of mapKeys) {
      switch (propertyDataMap[key]?.group) {
        case 'prop':
          propKeys.push(key)
          break
        case 'inspt':
          insptKeys.push(key)
          break
        case 'rect':
          rectKeys.push(key)
          break
      }
    }
    return (
      <Grid row>
        <Grid item xs={11} cssClass={Style.topRow}>
          <Text type='h4'>{selectedProp?.propAdd}</Text>
          {editForm ? null : <Button label='Edit' onClick={toggleFormEdit} cssClass={Style.button} />}
        </Grid>
        <TabMenu
          tabs={[
            { id: 0, label: 'Property' },
            { id: 1, label: 'Inspection' },
            { id: 2, label: 'Rectification' },
          ]}
          type='solid'
        >
          <TabContent for={0}>{propDetailFormTabContent(propKeys)}</TabContent>
          <TabContent for={1}>{propDetailFormTabContent(insptKeys)}</TabContent>
          <TabContent for={2}>{propDetailFormTabContent(rectKeys)}</TabContent>
        </TabMenu>
      </Grid>
    )
  }

  //  ---------- Render
  return (
    <Page footer>
      <Container fluid cssClass={Style.pageWrapper}>
        <Grid cssClass={Style.body}>
          <Modal
            isShowing={isShowing}
            onClose={toggleModal}
            actions={
              editForm
                ? [
                    { label: 'Cancel', type: 'secondary', onClick: onCancelFormEdit },
                    { label: 'Submit', onClick: onSubmitFormEdit },
                  ]
                : undefined
            }
          >
            {modalLoading ? loadingSpinner() : propDetailForm()}
          </Modal>
          {propDataTable()}
        </Grid>
      </Container>
    </Page>
  )
}

export default Property
