import { FC, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { Icon, InfoTooltip, Loader } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/Uploader.module.sass'

interface IUploaderProps {
  accept?: string[]
  maxSize?: number
  error?: string
  fileUrl?: string
  fileName?: string
  readonly?: boolean
  uploading: boolean
  clearUploader: boolean
  onFileChange?: (files: File[], error?: string) => void
}

const DEFAULT_MAX_SIZE = 1024 * 1024
const MIN_SIZE = 0

const Uploader: FC<IUploaderProps> = (props) => {
  const [documentUrl, setDocumentUrl] = useState<string>('')
  const [documentName, setDocumentName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')

  const { onFileChange, accept, fileUrl, maxSize, readonly, error, fileName, uploading, clearUploader } = props
  const fileMaxSize = DEFAULT_MAX_SIZE * (maxSize || 1)

  const onFileLoaded = useCallback(
    (files: File[], fileError?: string) => onFileChange && onFileChange(files, fileError),
    [onFileChange],
  )

  const onDrop = useCallback(
    (files: File[], fileRejection: FileRejection[]) => {
      onFileLoaded(files)

      if (files[0]) setDocumentUrl(URL.createObjectURL(files[0]))

      setDocumentName(files[0]?.name)
      const errors = fileRejection[0]?.errors[0]

      setErrorMessage(
        errors?.code === ErrorCode.FileTooLarge ? `The size of file is larger than the maximum size` : errors?.message,
      )
    },
    [onFileLoaded],
  )

  const { getRootProps, getInputProps, inputRef, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
    maxSize: fileMaxSize,
    minSize: MIN_SIZE,
    disabled: readonly,
    multiple: false,
  })

  useEffect(() => {
    setDocumentName(fileName || '')
  }, [fileName])

  useEffect(() => {
    setDocumentUrl(fileUrl || '')
    setErrorMessage(error || '')
  }, [fileUrl, error])

  useEffect(() => {
    setDocumentUrl('')
    onFileLoaded([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearUploader])

  const onDeleteFile = () => {
    setDocumentUrl('')
    onFileLoaded([])
  }

  const uploadPlaceHolder = () => {
    if (uploading) return <Loader type='line' label='Uploading...' />
    if (isDragActive) return <p>Drop the files here ...</p>

    return (
      <div className={Style.uploaderPlaceholder}>
        {
          <>
            <Icon cssClass={Style.addIcon} colour='#c4c4c4' size='48px' type='cloud_upload' />
            <div className={Style.title}>
              Drop files to upload or <a>browse</a>
            </div>
            <div className={Style.subtitle}>(Max file size: {maxSize}MB)</div>
            <div className={Style.tooltip}>
              <InfoTooltip
                show={
                  <span style={{ textAlign: 'left' }}>
                    <div className={Style.title}>Supported File Types</div>
                    <div className={Style.subtitle}>{accept?.join(', ')}</div>
                  </span>
                }
              ></InfoTooltip>{' '}
              List of supported file types
            </div>
          </>
        }
      </div>
    )
  }

  const uploaderClasses = classNames({
    [Style.uploaderContainer]: true,
    [Style.readonly]: readonly,
  })

  if (documentUrl) {
    return (
      <div className={uploaderClasses}>
        {!uploading && (
          <>
            <Icon type='picture_as_pdf' size='58px' colour='#c4c4c4' />
            <span className={Style.fileNameBox}>
              <p>{documentName}</p>
              {!readonly && <Icon type='close' size='20px' cssClass={Style.deleteIcon} onClick={onDeleteFile} />}
            </span>
          </>
        )}
        {uploading && <Loader label={`Uploading ${documentName}.`} />}
      </div>
    )
  }

  return (
    <>
      <div className={uploaderClasses} {...getRootProps()}>
        <input ref={inputRef} {...getInputProps()} />
        {uploadPlaceHolder()}
      </div>
      {errorMessage && <p className={Style.errorMessage}>{errorMessage}</p>}
    </>
  )
}

export default Uploader
