import { FC } from 'react'
import { Navbar, Grid } from '@aurecon-creative-technologies/styleguide'
import { ReactComponent as AureconLogo } from '../assets/aurecon_smoke_testing_app.svg'
import ProfileButton from './ProfileButton'

import Style from '../styles/Navbar.module.sass'

interface IPageLink {
  // page links that appear on navbar
  url: string
  label: string
}
interface INavbarProps {
  links: IPageLink[]
}

const CustomNavbar: FC<INavbarProps> = (props) => {
  return (
    <Grid row gap={0}>
      <Grid item cell left xs={11}>
        <Navbar logo={<AureconLogo className={Style.logo} />}>
          {props.links.map((link: IPageLink) => (
            <a key={link.url} href={link.url}>
              {link.label}
            </a>
          ))}
        </Navbar>
      </Grid>
      <Grid item cell right xs={1} cssClass={Style.profile}>
        <ProfileButton />
      </Grid>
    </Grid>
  )
}

export default CustomNavbar
