import { FC, useMemo, useState } from 'react'
import { Button, Container, Grid, Modal, Loader, Text } from '@aurecon-creative-technologies/styleguide'

import Uploader from '../components/Uploader'
import Page from '../components/Page'
import { appInsights } from '../api/AppInsights'
import { submitAddProjFile } from '../api/FileUploadService'
import Style from '../styles/AddProjectPage.module.sass'

const TEMPLATE_URL = 'upload_templates/WWOAP Smoke testing app new project template.csv'

const AddProject: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Home' })
  const [documentUrl, setDocumentUrl] = useState('')
  const [filename, setFilename] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [uploadErrorMsg, setUploadErrorMsg] = useState('')
  const [trigger, setTrigger] = useState(false)

  const formError = useMemo(() => {
    const errorMessage = []
    if (!uploadFile) errorMessage.push('csv file')

    return errorMessage.length ? errorMessage.join(', ') : null
  }, [uploadFile])

  const onFileChange = (file: File[]) => {
    setUploadFile(file?.length ? file[0] : null)
    setDocumentUrl('')
    setFilename(file?.length ? file[0].name : '')
  }

  const handleCloseModal = () => {
    clearForm()
    setShowModal(false)
  }

  const clearForm = () => {
    setFilename('')
    setDocumentUrl('')
    setUploadFile(null)
    setUploadError(false)
    setUploadErrorMsg('')
    setTrigger((value) => !value)
  }

  const handleSendRequest = async () => {
    if (!uploadFile) return

    setUploading(true)
    setShowModal(true)
    const res = await submitAddProjFile({
      files: uploadFile,
    })
    const result = res ? res : { success: false, message: 'No response.' }

    setUploading(false)
    setUploadError(!result.success)
    if (!result.success) {
      setUploadErrorMsg(result.message ? result.message : 'Unknown error')
    }
  }

  return (
    <Page footer>
      <Container cssClass={Style.pageWrapper}>
        <Grid cssClass={Style.body}>
          <Grid item cssClass={Style.content} xs={12} md={9}>
            <Text type='display2'>New Projects</Text>
            <Text type='h2'>Upload new properties to the database.</Text>
            <Text type='s2'>1. Fill in new property data with template below:</Text>
            <Button label='Template Download' onClick={() => window.open(TEMPLATE_URL)} />
            <Text type='s2'>2. Upload the populated .csv file:</Text>
            <Uploader
              accept={['.csv']}
              maxSize={200}
              fileName={filename}
              onFileChange={onFileChange}
              fileUrl={documentUrl}
              uploading={uploading}
              clearUploader={trigger}
            />

            <Button label='Submit' onClick={handleSendRequest} disabled={!!formError} />
          </Grid>
        </Grid>
      </Container>

      <Modal
        isShowing={showModal}
        onClose={handleCloseModal}
        actions={[{ label: 'Close', onClick: handleCloseModal, disabled: uploading }]}
      >
        {uploading && (
          <>
            <Text type='h2'>Uploading file</Text>
            <Text type='s2'>Please wait while the uploaded file is being proccessed...</Text>
            <Loader />
          </>
        )}
        {!uploading && !uploadError && (
          <>
            <Text type='h2'>New properties added</Text>
            <Text type='s2'>New properties added to database with Access Status "Pending"</Text>
          </>
        )}
        {uploadError && (
          <>
            <Text type='h2'>Upload error</Text>
            <Text type='s2'>{uploadErrorMsg}</Text>
            <Text type='s2'>Please rectify the error and upload again.</Text>
          </>
        )}
      </Modal>
    </Page>
  )
}

export default AddProject
