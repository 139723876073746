import { FC, useMemo, useState } from 'react'
import {
  Button,
  Container,
  Grid,
  Modal,
  TabContent,
  TabMenu,
  Loader,
  Text,
} from '@aurecon-creative-technologies/styleguide'

import Uploader from '../components/Uploader'
import Page from '../components/Page'
import { submitBulkUpdateFile } from '../api/FileUploadService'
import { appInsights } from '../api/AppInsights'
import Style from '../styles/BulkUpdatePage.module.sass'

const TEMPLATE_URL_INSPT = 'upload_templates/WWOAP Smoke testing app inspection bulk update dates template.csv'
const TEMPLATE_URL_RECT = 'upload_templates/WWOAP Smoke testing app rectification bulk update dates template.csv'

type Stage = 'inspection' | 'rectification'
const templateUrl: { [stage in Stage]: string } = { inspection: TEMPLATE_URL_INSPT, rectification: TEMPLATE_URL_RECT }

const BulkUpdate: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Home' })
  const [documentUrl, setDocumentUrl] = useState('')
  const [filename, setFilename] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [uploadErrorMsg, setUploadErrorMsg] = useState('')
  const [trigger, setTrigger] = useState(false)

  const formError = useMemo(() => {
    const errorMessage = []
    if (!uploadFile) errorMessage.push('csv file')

    return errorMessage.length ? errorMessage.join(', ') : null
  }, [uploadFile])

  const onFileChange = (file: File[]) => {
    setUploadFile(file?.length ? file[0] : null)
    setDocumentUrl('')
    setFilename(file?.length ? file[0].name : '')
  }

  const handleCloseModal = () => {
    clearForm()
    setShowModal(false)
  }

  const clearForm = () => {
    setFilename('')
    setDocumentUrl('')
    setUploadFile(null)
    setUploadError(false)
    setUploadErrorMsg('')
    setTrigger((value) => !value)
  }

  const onFileSubmit = (stage: Stage) => async () => {
    if (!uploadFile) return

    setUploading(true)
    setShowModal(true)
    const res = await submitBulkUpdateFile({
      files: uploadFile,
      stage: stage,
    })
    const result = res ? res : { success: false, message: 'No response.' }

    setUploading(false)
    setUploadError(!result.success)
    if (!result.success) {
      setUploadErrorMsg(result.message ? result.message : 'Unknown error')
    }
  }

  const tabContent = (stage: Stage) => {
    let requiredFields = null
    switch (stage) {
      case 'rectification':
        requiredFields = (
          <strong>Rectification Letter Sent Date, Rectification Permission Entry Letter Sent Date</strong>
        )
        break
      case 'inspection':
        requiredFields = <strong>NOE Send Date, NOE Expiry Date</strong>
    }

    return (
      <>
        <Text type='h2'>Bulk update for {stage}.</Text>
        <Text type='s2'>1. Fill in new property data with template below:</Text>
        <Text type='s2'>Please ensure {requiredFields} are populated for every property.</Text>
        <Text type='s2'>Other fields are optional.</Text>
        <Text type='s2' cssClass={Style.warning}>
          WARNING: Leaving any of the {requiredFields} blank in the upload will delete existing record in database. If
          there is no change to these field, use exisiting value in the upload.
        </Text>
        <Button label='Template Download' onClick={() => window.open(templateUrl[stage])} />
        <Text type='s2'>2. Upload the populated .csv file:</Text>
        <Uploader
          accept={['.csv']}
          maxSize={200}
          fileName={filename}
          onFileChange={onFileChange}
          fileUrl={documentUrl}
          uploading={uploading}
          clearUploader={trigger}
        />

        <Button label='Submit' onClick={onFileSubmit(stage)} disabled={!!formError} />
        {!!formError && (
          <p className={Style.formError}>
            You need to provide: <b>{formError}</b>
          </p>
        )}
      </>
    )
  }

  return (
    <Page footer>
      <Container cssClass={Style.pageWrapper}>
        <Grid cssClass={Style.body}>
          <Grid item cssClass={Style.content} xs={12} md={9}>
            <Text type='display2'>Bulk Update</Text>
            <TabMenu
              tabs={[
                { id: 0, label: 'Inspection' },
                { id: 1, label: 'Rectification' },
              ]}
              type='solid'
            >
              <TabContent for={0}>{tabContent('inspection')}</TabContent>
              <TabContent for={1}>{tabContent('rectification')}</TabContent>
            </TabMenu>
          </Grid>
        </Grid>
      </Container>

      <Modal
        isShowing={showModal}
        onClose={handleCloseModal}
        actions={[{ label: 'Close', onClick: handleCloseModal, disabled: uploading }]}
      >
        {uploading && (
          <>
            <Text type='h2'>Uploading file</Text>
            <Text type='s2'>Please wait while the uploaded file is being proccessed...</Text>
            <Loader />
          </>
        )}
        {!uploading && !uploadError && (
          <>
            <Text type='h2'>Property data updated</Text>
            <Text type='s2'>Property data updated with Access Status "Approved"</Text>
          </>
        )}
        {uploadError && (
          <>
            <Text type='h2'>Upload error</Text>
            <Text type='s2'>{uploadErrorMsg}</Text>
            <Text type='s2'>Please rectify the error and upload again.</Text>
          </>
        )}
      </Modal>
    </Page>
  )
}

export default BulkUpdate
