import { postAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export interface IGetDBInfoRequest extends IBodyRequestModel {
  filters: string
}

export const getDBInfo = TokenExpiryWrapper(
  async (request: IGetDBInfoRequest): Promise<IResponse<string>> => {
    const url = `/dbinfo/`
    return await postAsync(url, request)
  },
  [],
  null,
)
