import { FC, useState } from 'react'
import { Button, Modal } from '@aurecon-creative-technologies/styleguide'
import { useAuth0 } from '@auth0/auth0-react'

import config from '../config/config'

import Style from '../styles/ProfileButton.module.sass'

const ProfileButton: FC = () => {
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false)
  const { user, logout } = useAuth0()
  const handleLogout = () => {
    const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
    logout({ returnTo })
  }

  return (
    <>
      <Button type='icon-round' icon='person' onClick={() => setShowProfileModal(true)} />
      <Modal isShowing={showProfileModal} onClose={() => setShowProfileModal(false)}>
        <div className={Style.layoutContainer}>
          <p style={{ marginTop: '32px' }}>
            <img src={user?.picture} alt='user' sizes='64px' />
          </p>
          <p>{user?.email} (User)</p>
          <Button type='primary' label='Logout' width='300px' onClick={handleLogout} />
        </div>
      </Modal>
    </>
  )
}

export default ProfileButton
