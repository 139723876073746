export const SUPPORT_EMAIL = 'smoke-testing.support@aurecongroup.com'
export const JIRA_SUPPORT_URL = ''
export const ABOUT_URL = 'https://www.aurecongroup.com/expertise/environmental-management'
export const REQUEST_ACCESS_URL = ''
export const MAX_FILE_NAME = 80

const config = {
  AUTH0_DOMAIN: 'auth.aurecongroup.digital',
  AUTH0_CLIENT_ID: 'TbxyPmEJX3sNdDGNv0mJ6kkrFzPE70iJ',
  AUTH0_AUDIENCE: 'https://aurecon-smoke.aurecongroup.digital',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'https://aurecongroup.com/roles',
  AUTH0_GLOBAL_ADMIN_ROLE: 'smoke-testing Global Admin',
  MOBLIE_VIEW_MAX_WIDTH: 768,
}

export default config
