export type RawPropertyData = {
  // raw data type from SQL backend
  'Property Number': number
  'Property Number Text'?: string
  'Project Name'?: string
  'Polygon Name'?: string

  'Property Address': string
  'Property Suburb'?: string
  'Property LGA'?: string
  'Property Lot and DP'?: string
  'Property Type'?: string
  'Property Subtype'?: string

  'Inspection Delivery Phase'?: string
  'Inspection Intro Letter Send Date'?: number
  'Inspection NOE Letter Send Date'?: number
  'Inspection NOE Expiry Date'?: number
  'Inspection Days since NOE Expired'?: number
  'Inspection Customer Response'?: string
  'Inspection Customer Response Details'?: string
  'Inspection Property Access Status'?: string
  'Inspection Status'?: string
  'Inspection Reason'?: string
  'Inspection Date'?: number
  'Inspection Defect Identified'?: string
  'Inspection Reviewed'?: string
  'Inspection Site Complete'?: string

  'Rectification Stage'?: string
  'Rectification Letter Sent Date'?: number
  'Rectification Permission Entry Letter Sent Date'?: number
  'Rectification Pre-inspection Date'?: number
  'Rectification Surveying Date'?: number
  'Rectification Council Application Date'?: number
  'Rectification Start Date'?: number
  'Rectification End Date'?: number
  'Rectification Thank You Letter Date'?: number
  'Rectification Customer Response Dropdown'?: string
  'Rectification Customer Response Details'?: string
  'Rectification Customer Response'?: string
  'Rectification Field Status'?: string
  'Rectification Reviewed'?: string
  'Rectification Status'?: string

  'Modification Timestamp UTC'?: number
  'Modification Timestamp': number
}

export const isRawPropertyData = (data: object): data is RawPropertyData => {
  // type checking for RawPropertyData
  if (data === null) {
    console.warn('Not RawPropertyData: data is null', data)
    return false
  }
  if (typeof data !== 'object') {
    console.warn('Not RawPropertyData: data is not object', data)
    return false
  }

  // check required keys
  const requiredKeys: (keyof RawPropertyData)[] = ['Property Number', 'Property Address', 'Modification Timestamp']
  for (const key of requiredKeys) {
    if (!(key in data)) {
      console.warn('Not RawPropertyData: ' + key + ' not found in data', data)
      return false
    }
  }
  return true
}

export type PropertyData = {
  // property data object used in webapp
  propId: number
  propIdText?: string
  projectName?: string
  polygonName?: string

  propAdd: string
  propSuburb?: string
  progLga?: string
  propLot?: string
  propType?: string
  propSubType?: string

  insptDeliveryPhase?: string
  insptIntroLetterSendDate?: string
  insptNoeLetterSendDate?: string
  insptNoeExpDate?: string
  insptNoeExpired?: string
  insptCustomerResponse?: string
  insptCustomerResponseDetails?: string
  insptAccessStatus?: string
  insptFieldStatus?: string
  insptReason?: string
  insptDate?: string
  insptDefectIdentified?: string
  insptReviewed?: string
  insptSiteComplete?: string

  rectStage?: string
  rectLetterSendDate?: string
  rectEntryLetterSendDate?: string
  rectPreInspectDate?: string
  rectSurveyDate?: string
  rectCouncilAppDate?: string
  rectStartDate?: string
  rectEndDate?: string
  rectThankLetterDate?: string
  rectCustomerResponseDropdown?: string
  rectCustomerResponseDetails?: string
  rectCustomerResponse?: string
  rectFieldStatus?: string
  rectReviewed?: string
  rectStatus?: string

  modTimestampUTC?: string
  modTimestamp: string
}

export const isPropertyData = (data: object): data is PropertyData => {
  // type checking for PropertyData
  if (data === null) {
    console.warn('Not PropertyData: data is null', data)
    return false
  }
  if (typeof data !== 'object') {
    console.warn('Not PropertyData: data is not object', data)
    return false
  }

  // check required keys
  const requiredKeys: (keyof PropertyData)[] = ['propId', 'propAdd', 'modTimestamp']
  for (const key of requiredKeys) {
    if (!(key in data)) {
      console.warn('Not PropertyData: ' + key + ' not found in data', data)
      return false
    }
  }
  return true
}

// ---------- Map SQL data field
// data type for dynamic rendering
export const optComponentDataType = ['numeric', 'text', 'date', 'timestamp'] as const
type ComponentDataType = typeof optComponentDataType[number]

export type PropertyDataMappingSchema = {
  // mapping schema from raw data to property data used in frontend
  [key in keyof PropertyData]: {
    rawKey: keyof RawPropertyData // key used in SQL database
    dataType: ComponentDataType // type of component used for this field
    group: 'prop' | 'inspt' | 'rect' // group that the field belongs to
    options?: string[] // allowable options for the field
    readOnly?: boolean // flag the field as read-only (disable edit)
    hidden?: boolean // flag the field as hidden (from details form)
  }
}

export const propertyDataMap: PropertyDataMappingSchema = {
  propId: { rawKey: 'Property Number', dataType: 'text', readOnly: true, group: 'prop' },
  propIdText: { rawKey: 'Property Number Text', dataType: 'text', hidden: true, group: 'prop' },
  projectName: { rawKey: 'Project Name', dataType: 'text', readOnly: true, group: 'prop' },
  polygonName: { rawKey: 'Polygon Name', dataType: 'text', readOnly: true, group: 'prop' },

  propAdd: { rawKey: 'Property Address', dataType: 'text', readOnly: true, group: 'prop' },
  propSuburb: { rawKey: 'Property Suburb', dataType: 'text', readOnly: true, group: 'prop' },
  progLga: { rawKey: 'Property LGA', dataType: 'text', readOnly: true, group: 'prop' },
  propLot: { rawKey: 'Property Lot and DP', dataType: 'text', readOnly: true, group: 'prop' },
  propType: { rawKey: 'Property Type', dataType: 'text', readOnly: true, group: 'prop' },
  propSubType: { rawKey: 'Property Subtype', dataType: 'text', readOnly: true, group: 'prop' },

  insptDeliveryPhase: { rawKey: 'Inspection Delivery Phase', dataType: 'text', readOnly: true, group: 'inspt' },
  insptIntroLetterSendDate: {
    rawKey: 'Inspection Intro Letter Send Date',
    dataType: 'date',
    readOnly: true,
    group: 'inspt',
  },
  insptNoeLetterSendDate: {
    rawKey: 'Inspection NOE Letter Send Date',
    dataType: 'date',
    readOnly: true,
    group: 'inspt',
  },
  insptNoeExpDate: { rawKey: 'Inspection NOE Expiry Date', dataType: 'date', readOnly: true, group: 'inspt' },
  insptNoeExpired: { rawKey: 'Inspection Days since NOE Expired', dataType: 'text', readOnly: true, group: 'inspt' },
  insptCustomerResponse: {
    rawKey: 'Inspection Customer Response',
    dataType: 'text',
    options: ['', 'Access Denied', 'With Instruction'],
    group: 'inspt',
  },
  insptCustomerResponseDetails: {
    rawKey: 'Inspection Customer Response Details',
    dataType: 'text',
    readOnly: false,
    group: 'inspt',
  },
  insptAccessStatus: { rawKey: 'Inspection Property Access Status', dataType: 'text', readOnly: true, group: 'inspt' },
  insptFieldStatus: { rawKey: 'Inspection Status', dataType: 'text', readOnly: true, group: 'inspt' },
  insptReason: { rawKey: 'Inspection Reason', dataType: 'text', readOnly: true, group: 'inspt' },
  insptDate: { rawKey: 'Inspection Date', dataType: 'date', readOnly: true, group: 'inspt' },
  insptDefectIdentified: { rawKey: 'Inspection Defect Identified', dataType: 'text', readOnly: true, group: 'inspt' },
  insptReviewed: { rawKey: 'Inspection Reviewed', dataType: 'text', readOnly: true, group: 'inspt' },
  insptSiteComplete: { rawKey: 'Inspection Site Complete', dataType: 'text', readOnly: true, group: 'inspt' },

  rectStage: { rawKey: 'Rectification Stage', dataType: 'text', readOnly: true, group: 'rect' },
  rectLetterSendDate: { rawKey: 'Rectification Letter Sent Date', dataType: 'date', readOnly: false, group: 'rect' },
  rectEntryLetterSendDate: {
    rawKey: 'Rectification Permission Entry Letter Sent Date',
    dataType: 'date',
    readOnly: false,
    group: 'rect',
  },
  rectPreInspectDate: { rawKey: 'Rectification Pre-inspection Date', dataType: 'date', readOnly: false, group: 'rect' },
  rectSurveyDate: { rawKey: 'Rectification Surveying Date', dataType: 'date', readOnly: false, group: 'rect' },
  rectCouncilAppDate: {
    rawKey: 'Rectification Council Application Date',
    dataType: 'date',
    readOnly: false,
    group: 'rect',
  },
  rectStartDate: { rawKey: 'Rectification Start Date', dataType: 'date', readOnly: false, group: 'rect' },
  rectEndDate: { rawKey: 'Rectification End Date', dataType: 'date', readOnly: false, group: 'rect' },
  rectThankLetterDate: {
    rawKey: 'Rectification Thank You Letter Date',
    dataType: 'date',
    readOnly: false,
    group: 'rect',
  },
  rectCustomerResponseDropdown: {
    rawKey: 'Rectification Customer Response Dropdown',
    dataType: 'text',
    options: [
      '',
      'Yes',
      'No - I do not want to undertake repairs',
      'No - I prefer to seek advice with my own plumber',
      'No response',
    ],
    group: 'rect',
  },
  rectCustomerResponseDetails: {
    rawKey: 'Rectification Customer Response Details',
    dataType: 'text',
    readOnly: false,
    group: 'rect',
  },
  rectCustomerResponse: {
    rawKey: 'Rectification Customer Response',
    dataType: 'text',
    readOnly: true,
    group: 'rect',
  },
  rectFieldStatus: { rawKey: 'Rectification Field Status', dataType: 'text', readOnly: true, group: 'rect' },
  rectReviewed: { rawKey: 'Rectification Reviewed', dataType: 'text', readOnly: true, group: 'rect' },
  rectStatus: { rawKey: 'Rectification Status', dataType: 'text', readOnly: true, group: 'rect' },

  modTimestampUTC: {
    rawKey: 'Modification Timestamp UTC',
    dataType: 'timestamp',
    hidden: true,
    readOnly: true,
    group: 'prop',
  },
  modTimestamp: {
    rawKey: 'Modification Timestamp',
    dataType: 'timestamp',
    hidden: true,
    readOnly: true,
    group: 'prop',
  },
}

export const wrapPropertyData = (
  rawData: RawPropertyData,
  dataMap: PropertyDataMappingSchema,
): Partial<PropertyData> => {
  // wrap raw data into proper data object
  let wrappedData = {}
  for (const mapKey in dataMap) {
    const rawKey: keyof RawPropertyData = dataMap[mapKey].rawKey
    const dataType: ComponentDataType = dataMap[mapKey].dataType
    let data = rawData[rawKey]

    // convert date time format
    switch (dataType) {
      case 'date':
      case 'timestamp':
        if (typeof data === 'number') {
          data = new Date(data).toISOString().replace('T', '\n').split('.')[0] // yyyy-mm-dd
        }
        break
    }
    // store data
    wrappedData = { ...wrappedData, [mapKey]: data }
  }

  // return null if wrappedData dose not pass data check
  return wrappedData
}

export const mapKeysToRawPropertyData = (data: Partial<PropertyData>): Partial<RawPropertyData> => {
  // map keys back to those in RawPropertyData
  let mappedData = {}
  for (const dataKey in data) {
    const mappedKey = propertyDataMap[dataKey].rawKey
    mappedData = { ...mappedData, [mappedKey]: data[dataKey] }
  }
  return mappedData
}
